// Advance tab Styles 

.advance-tab-button-1 {
    .tab-button-list {
        list-style: none;
        padding: 0;
        margin: -10px 0;
        li {
            margin: 10px 0;
        }
        .tab-button {
            cursor: pointer;
            padding: 47px 27px;
            border-radius: 10px;
            position: relative;
            z-index: 2;
            background-color: transparent;
            border: 0 none;
            margin: 0;
            
            &::before {
                position: absolute;
                content: "";
                background: var(--color-blackest);
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transition: 0.3s;
                opacity: 0;
                transform: scale(0.9);
                z-index: -1;
                border-radius: 7px;
            }

            &:hover {
                &::before {
                    opacity: 1;
                    transform: scale(1);
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 100%;
                border-left: 20px solid var(--color-blackest);
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                margin-top: -20px;
                opacity: 0;
                transition: 0.3s;
                transform: scale(0.9);
                @media #{$md-layout} {
                    display: none;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
            .title {
                color: var(--color-body);
                font-size: 20px;
                margin-bottom: 10px;
                transition: 0.3s;
            }
            .description {
                margin-bottom: 0;
                transition: 0.3s;
            }
        }
        .tab-button {
            &.active {
                .title {
                    color: var(--color-white);
                }
                &::before {
                    opacity: 1;
                    transform: scale(1);
                }
                &::after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
           
        }
    }
}

.nav-tabs {
    border-bottom: 0;
    
}


.advance-tab-content-1 {
    .thumbnail {
        img {
            border-radius: 10px;
        }
    }
}



// Right Align 

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
    transform: scale(0.9) rotate(-180deg);
    left: auto;
    right: 100%;
}
.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
    transform: scale(1) rotate(-180deg);
}
.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
    left: 50%;
    transform: scale(0.9) translateX(-50%) rotate(-92deg);
    bottom: 94%;
    top: auto;
}
.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
    opacity: 0;
    transform: scale(1) translateX(-50%) rotate(-92deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button.active::after {
    opacity: 1;
}

.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active ,
.nav-tabs .nav-link:focus {
    color: #495057;
    background: transparent;
    border: 0 none;
    box-shadow: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.advance-tab-button-1 .tab-button-list .tab-button:focus {
    outline: none;
    border: 0 none;
    box-shadow: none;
}
